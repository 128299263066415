import * as React from "react"
import Header from "../../../components/Header";
import '../../../templates/styles.scss';

// markup
const PrivacyPage = () => {

  const country = "en";
  const language = "en";

  return (
    <>
      <Header language={language} country={country} urlParts={["/en/privacy"]} />
      <div className="home-wrapper" style={{textAlign: "center"}}>
        <h1>Our privacy policy</h1>        
        <p>When you use this site or our app, you share some information with us.</p>        
        <p>Like most other websites, we use cookies, which are small files stored on your computer that allow us to track you across sessions. This isn't as sinister as it sounds - it just means that if you come back to the site later, we can work out that you are the same person. Currently the only cookies we use are from Google Analytics.</p>
        <p>If you use our app, we use your device ID instead of a cookie to track that you are the same person each time you use it.</p>        
        <p>We only use that information to understand who is interacting with the site and app, and to get in touch with you when you request us to. We never use it for any other purpose, nor pass it on to a third party without your permission.</p>        
        <p>In addition, when you submit information using the contact form, you are sending us that information via the website. This includes your name, email address and information about your location. You don't have to give us this information to use the app or the site, and you can opt out any time by emailing privacy@thedigitalhome.org.  We will only ever use that information for the reason stated when you give it.</p> 
      </div>
    </>    
  )
}

export default PrivacyPage
